import React from "react";
import Indicator from "./Indicator";
import Container from "react-bootstrap/Container";

function RightBar() {
    return (
        <div className="col-2 d-none d-lg-block m-0 p-0">
            <Container className="mt-3"><Indicator symbol="BTC" name="sentiment" title="Fear & Greed" /></Container>
        </div>
    )
}

export default RightBar;