import {DataGrid} from '@mui/x-data-grid';
import React, {useEffect, useState} from 'react'
import fetchData from "../utils/Fetch";
import Loader from "./Loader";
import numeral from "numeral";
import {faStar, faCaretUp, faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {doc, getDoc, setDoc} from 'firebase/firestore';
import {auth, db} from "../config/firebase"
import {onAuthStateChanged} from "firebase/auth";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import { Box } from '@mui/material';
import { Textfit } from 'react-textfit';
import limitSignificantDigits from "../utils/LimitSignificantDigits";
import useWebSocket from "../utils/WS";

const Tickers = () => {
    type Bookmarks = {
        [key: string]: boolean;
    };
    const [rows, setRows] = React.useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 100,
    });
    const [sortModel, setSortModel] = React.useState([{field: 'marketCap', sort: 'desc'}]);
    const [rowCount, setRowCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true);
    const [message, setMessage] = React.useState('')
    const [error, setError] = useState('')
    const [bookmarks, setBookmarks] = useState<Bookmarks>({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(992));
    const [columnsMap, setColumnsMap] = useState<Map<string, boolean>>(new Map());
    const [isDarkMode, setIsDarkMode] = React.useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        return savedMode === null ? false : savedMode === 'true';
    });

    useEffect(() => {
        setInterval(() => {
            window.dispatchEvent(new Event('resize'))
        }, 250)
    }, []);

    useWebSocket((message: any) => {
        const data = JSON.parse(message.data);
        const priceElement = document.getElementById(`${data.symbol}-price`);
        if (priceElement) {
            const element = priceElement.querySelector('div')
            if (element) {
                const currentPrice = +element.innerText
                const newPrice = limitSignificantDigits(data.price, 8)
                element.innerText = newPrice.toString()
                if (newPrice >= currentPrice) {
                    element.classList.add('pricer-up')
                } else {
                    element.classList.add('pricer-down')
                }
                setTimeout(() => {
                    element.classList.remove('pricer-up', 'pricer-down')
                }, 1000)
            }
        }
    })

    useEffect(() => {
        const updateMode = () => {
            setTimeout(() => {
                const savedMode = localStorage.getItem('darkMode');
                if (savedMode) {
                    setIsDarkMode(savedMode === 'true')
                }
            }, 0)
        }
        window.addEventListener('darkModeUpdated', updateMode);
        return () => {
            window.removeEventListener('darkModeUpdated', updateMode);
        };
    }, []);

    const setCustomSort = (model: any) => {
        if (model.length === 0) {
            const {field, sort} = sortModel[0];
            setSortModel([{field, sort: sort === "asc" ? "desc" : "asc"}]);
        } else {
            setSortModel(model)
        }
    }

    useEffect(() => {
        const updateColumns = () => {
            setTimeout(() => {
                const storedMap = localStorage.getItem('columns');
                if (storedMap) {
                    setColumnsMap(new Map(JSON.parse(storedMap)));
                }
            }, 0)
        }
        window.addEventListener('updatedColumns', updateColumns);
        updateColumns()
        return () => {
            window.removeEventListener('updatedColumns', updateColumns);
        };
    }, []);

    useEffect(() => {
        fetchData(`/api/tickers?page=${paginationModel.page + 1}&pageSize=${paginationModel.pageSize}&sortField=${sortModel[0]?.field ?? 'marketCap'}&sortDirection=${sortModel[0]?.sort ?? 'desc'}&ticker=`, {
            setError,
            setIsLoading,
            setMessage
        }).then((response) => {
            if (response) {
                setRowCount(response.total)
                setRows(response.tickers.map((ticker: any) => ({...ticker, price: {ticker: ticker.ticker, price: ticker.price}})))
            }
        })
    }, [paginationModel, sortModel])

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user || !user.uid) {
                return
            }
            const userBookmarksRef = doc(db, `bookmarks/${user.uid}`);
            getDoc(userBookmarksRef).then((snap) => {
                if (snap.exists()) {
                    const data = snap.data()
                    if (data === undefined) {
                        setBookmarks({})
                    } else {
                        setBookmarks(snap.data())
                    }
                }
            })
        });
        return () => unsubscribe();
    }, []);

    const setBookmark = async (id: string) => {
        const uid = auth.currentUser?.uid;
        if (!uid) {
            window.location.href = "/login"
            return
        }
        const userBookmarksRef = doc(db, `bookmarks/${uid}`);
        setBookmarks((prevBookmarks) => {
            const newBookmarks = { ...prevBookmarks, [id]: !prevBookmarks[id] };
            setDoc(userBookmarksRef, newBookmarks, { merge: true });
            return newBookmarks;
        });
    }

    const getSortIcon = () => {
        // return <FontAwesomeIcon icon={sortModel[0]?.sort === "desc" ? faCaretDown : faCaretUp} className="fa-sm mx-1"/>
        return <></>
    }

    const getHeader = (id: string, name: string) => {
        return (<Box display="flex" className="MuiDataGrid-columnHeader MuiDataGrid-columnHeaderTitle">{sortModel[0]?.field === id ? getSortIcon() : ""}{name}</Box>)
    }

    const columns: any = [
        {
            field: 'icon',
            headerName: '',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            maxWidth: 20,
            sortable: true,
            renderCell: ({value}: any) => {
                return (
                    <img src={value} alt="icon" className="ticker-icon" />
                )
            },
        },
        {
            field: 'ticker',
            headerName: 'Symbol',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            sortable: true,
            renderCell: ({value}: any) => {
                return (
                    <a href={`/symbol/${value}`} className="numeric-link-secondary">{value}</a>
                )
            },
            renderHeader: () => (
                getHeader("ticker", "Symbol")
            ),
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            align: 'center',
            sortable: true,
            renderCell: ({value}: any) => {
                return <Textfit mode="single" className="w-100 text-center pricer" throttle={50} max={13} id={`${value.ticker}-price`}>{limitSignificantDigits(value.price, 8)}</Textfit>
            },
            headerAlign: 'center',
            renderHeader: () => (
                getHeader("price", "Price")
            ),
        },
        {
            field: 'volume',
            headerName: 'volume',
            flex: 1,
            align: 'center',
            sortable: true,
            renderCell: ({value}: any) => {
                return value !== undefined ? numeral(value).format('0.00a').toUpperCase() : ''
            },
            headerAlign: 'center',
            renderHeader: () => (
                getHeader("volume", "volume")
            ),
        },
        {
            field: 'marketCap',
            headerName: 'Cap',
            flex: 1,
            align: 'center',
            sortable: true,
            renderCell: ({value}: any) => {
                return value !== undefined ? numeral(value).format('0.00a').toUpperCase() : ''
            },
            headerAlign: 'center',
            renderHeader: () => (
                getHeader("marketCap", "Cap")
            ),
        },
        {
            field: 'changePercent',
            headerName: 'Change',
            flex: 1,
            align: 'center',
            sortable: true,
            renderCell: ({value}: any) => {
                let arrow = faCaretUp
                if (value < 0) {
                    arrow = faCaretDown
                }
                const price = value !== undefined ? numeral(value).format('0.00a').toUpperCase() : ''
                return <b className={value > 0 ? "numeric-green-text rounded-1" : "numeric-red-text rounded-1"}><FontAwesomeIcon icon={arrow} className="fa-sm"/> {price.replace("-", "")}%</b>
            },
            headerAlign: 'center',
            renderHeader: () => (
                getHeader("changePercent", "Change")
            ),
        },
        {
            field: 'id',
            headerName: '',
            flex: 1,
            maxWidth: 20,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            renderCell: ({value}: any) => {
                const color = isDarkMode ? '#6A5ACD' : '#2866F8'
                return (
                    <FontAwesomeIcon style={{filter: 'grayscale(0) invert(0)'}} icon={faStar} className="fa-sm mx-2" color={bookmarks[value]? color : ""} onClick={() => setBookmark(value)}/>
                )
            }
        },
    ];

    const grid = () => {
        // @ts-ignore
        return (<DataGrid
            columns={columns}
            rows={rows}
            rowCount={rowCount}
            initialState={{
                pagination: {paginationModel: paginationModel},
                sorting: {
                    // @ts-ignore
                    sortModel: sortModel
                },
            }}
            columnVisibilityModel={{
                // @ts-ignore
                marketCap: columnsMap.get('marketCap'),
                // @ts-ignore
                volume: columnsMap.get('volume') === undefined ? !isMobile : columnsMap.get('volume'),
                // @ts-ignore
                id: columnsMap.get('bookmark'),
            }}
            disableColumnMenu={true}
            pagination={true}
            sortingMode="server"
            filterMode="server"
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            // @ts-ignore
            onSortModelChange={setCustomSort}
            pageSizeOptions={[10, 25, 50, 100]}
            className="d-grid"
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
            }
        />)
    }

    return (
        <Loader component={grid} isLoading={isLoading} error={error} message={message}/>
    )
}

export default Tickers;